.theme-viewer {
  &__gallery {
    text-align: center;
  }

  .device {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 50%;
    transform: perspective(1000px) rotateY(20deg);

    &:nth-child(2) {
      margin-left: -20%;
      margin-top: 20%;
    }

    .frame {
      z-index: 3;
      width: 100%;
      position: relative;
    }

    .panel,
    .bg {
      position: absolute;
      top: 4.29%;
      height: 88.86%;
      left: 11.43%;
      width: 77.32%;
    }

    .bg {
      z-index: 1;
      background-color: #333;
    }

    .panel {
      opacity: 0;
      transition: opacity 0.6s;
      z-index: 1;

      &.active {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  .navigation {
    &__choose {
      display: none;
    }

    &__title {
      text-align: center;
      display: block;
    }

    &__items {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .selector {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.7;

      &__dot {
        flex-shrink: 0;
        height: 36px;
        width: 36px;
        border-radius: 6px;
        border-width: 4px;
        border-style: solid;
        transition: background-color 0.3s;
      }

      &__label {
        display: none;
      }

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .theme-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .theme-viewer .device {
    width: 300px;
  }

  .theme-viewer .navigation__choose {
    display: block;
  }

  .theme-viewer .navigation__title {
    display: none;
  }

  .theme-viewer .navigation__items {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .theme-viewer .navigation .selector__label {
    display: block;
  }
}
