.footer {
  background: $gray-200;
  color: $gray-600;

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    margin-bottom: $spacer;
  }

  &__caption {
    text-align: center;
    margin-bottom: $spacer;
  }

  .app-v {
    color: $gray-300;
    font-size: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .footer {
    &__row {
      flex-direction: row;
    }

    &__logo {
      margin-bottom: 0;
      margin-right: $spacer;
    }

    &__caption {
      text-align: left;
      margin-bottom: 0;
    }

    &__spacer {
      flex-grow: 1;
    }
  }
}
