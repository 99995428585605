/**
  BOOTSTRAP OVERRIDE
*/

$font-family-base: 'Quicksand', sans-serif;
$display-font-weight: 700;

$headings-line-height: 1.6;

$primary: #4a90e2;
$secondary: #ffcc00;
$info: #fb0061;

$body-bg: #1b1e27;
$body-color: #fff;

$btn-border-radius: 3rem;
$btn-border-radius-sm: 2rem;
$btn-link-color: #ced4da;

$link-decoration: none;

$enable-negative-margins: true;
