.logo {
  height: 64px;
}

.logo-circle {
  position: relative;
  height: 84px;
  width: 84px;
  border-radius: 50%;
  background: rgba($color: $black, $alpha: 0.45);

  img {
    position: absolute;
    display: block;
    height: 64px;
    width: 64px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.parallax-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: scroll;
}

@include media-breakpoint-up(lg) {
  .parallax-cover {
    background-attachment: fixed;
  }
}
