// icon font
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');

// bootstrap variables override
@import './variables.scss';

// bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// generics
@import './mixins.scss';
@import './utils.scss';
@import './animations.scss';

// components

@import './components/divider.scss';
@import './components/section.scss';
@import './components/form.scss';
@import './components/footer.scss';
@import './components/theme-viewer.scss';
@import './components/slider.scss';
@import './components/media.scss';

// sections

@import './sections/intro.scss';

p {
  font-weight: $font-weight-light;
}
