.section {
  padding-top: map-get($spacers, 5);
  padding-bottom: map-get($spacers, 5);

  &__header {
    text-align: center;
  }

  &--contrast {
    background-color: #15263a;
  }

  &--fullscreen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;

    .section__header {
      flex-shrink: 0;
      overflow: hidden;
    }

    .section__content {
      flex-grow: 1;
      overflow: hidden;
    }
  }
}

.section-title {
  display: inline-block;
  border-bottom: 2px solid $secondary;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}
