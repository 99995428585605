$animation-duration: 0.6s;

#section-intro {
  position: relative;
  background-image: linear-gradient(
      35deg,
      rgba(27, 30, 39, 0.8),
      rgba(27, 30, 39, 0.5)
    ),
    url('../../assets/img-copertina-2.jpg');

  .topbar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .title {
    opacity: 0;
    animation: fadeTop $animation-duration;
    animation-fill-mode: forwards;
    width: 100%;
    object-fit: contain;
  }

  .subtitle {
    text-align: right;

    span {
      display: inline-block;
      margin-right: 4px;
      opacity: 0;
    }

    span:nth-child(1) {
      animation-name: fadeLeft;
      animation-duration: $animation-duration;
      animation-delay: 0.3s;
      animation-fill-mode: forwards;
    }

    span:nth-child(2) {
      animation-name: fadeLeft;
      animation-duration: $animation-duration;
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
    }
  }

  .cta {
    position: absolute;
    bottom: 20px;
    opacity: 0;
    animation: fadeBottom $animation-duration;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
  }
}
