@mixin activeArea($direction) {
  background: linear-gradient(
    to $direction,
    rgba($color: $gray-100, $alpha: 0.25),
    rgba($color: $gray-100, $alpha: 0)
  );
}

.slider {
  position: relative;

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__dir {
    display: none;
    flex-shrink: 0;
  }

  .gallery {
    flex-shrink: 0;
    position: relative;
    text-align: center;

    &__content {
      margin: 0 auto;
      position: relative;
      overflow: hidden;
    }

    &__device {
      width: 100%;
      position: relative;
      z-index: 3;
    }

    &__frame {
      position: absolute;
      overflow: hidden;
      z-index: 1;
    }

    &__frame--controls {
      z-index: 5;
    }

    &__fakebar {
      display: none;
      position: absolute;
      z-index: 4;
      left: 0;
      top: 0;
      width: 100%;
    }

    &__next-area,
    &__prev-area {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
    }

    &__prev-area {
      left: 0;

      &:active {
        @include activeArea(left);
      }
    }

    &__next-area {
      left: 50%;

      &:active {
        @include activeArea(right);
      }
    }

    &__slide {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;

      &.prev-active {
        opacity: 1;
        z-index: 2;
      }

      &.active {
        opacity: 1;
        z-index: 3;
        animation: slideUp 0.3s;
      }
    }

    &--notebook {
      .gallery__content {
        width: 500px;
      }

      .gallery__frame {
        top: 6.55%;
        height: 80.54%;
        left: 15.3%;
        width: 69.49%;
      }
    }

    &--desktop {
      .gallery__content {
        width: 80vw;
      }

      .gallery__frame {
        top: 2.05%;
        height: 65.53%;
        left: 1.86%;
        width: 96.37%;
      }

      .gallery__slide {
        top: 3.4%;
      }

      .gallery__fakebar {
        display: block;
        background: #2c5688;
        height: 3.4%;
      }
    }

    &--phone {
      .gallery__content {
        width: 60vw;
      }

      .gallery__frame {
        top: 4.29%;
        height: 88.86%;
        left: 11.43%;
        width: 77.32%;
      }
    }
  }

  .caption {
    &__slide {
      margin: auto;
      text-align: center;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .navigation {
    text-align: center;

    &__dot {
      display: inline-block;
      border-radius: 100%;
      width: 10px;
      height: 10px;
      background: $white;
      opacity: 0.3;
      transition: background-color 0.3s;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &.active {
        opacity: 1;
        background-color: $secondary;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .slider .slider__dir {
    display: block;
  }

  .slider .gallery .gallery__device {
    height: 100%;
    width: auto;
  }

  .slider .gallery.gallery--desktop .gallery__content {
    height: 60vh;
    width: auto;
  }

  .slider .gallery.gallery--notebook .gallery__content {
    height: 60vh;
    width: auto;
  }

  .slider .gallery.gallery--phone .gallery__content {
    height: 60vh;
    width: auto;
  }

  .slider .caption .caption__slide {
    width: 70%;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(70%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
