.ean-grow,
.ean-grow-width,
.ean-fade-left,
.ean-fade-bottom {
  opacity: 0;
}

.in-viewport {
  .ean-grow {
    animation: grow 0.6s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .ean-grow-width {
    animation: growWidth 0.6s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .ean-fade-left {
    animation-name: fadeLeft;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }

  .ean-fade-bottom {
    animation-name: fadeBottom;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeBottom {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeTop {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes growWidth {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  80% {
    opacity: 0.8;
    transform: scaleX(1.2);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
